import { useMutation } from '@tanstack/react-query';

import {
  API_ANALYTICS_SNAPSHOT_CONTINUED,
  API_ANALYTICS_VISIT_PUBLIC_SNAPSHOT,
} from 'constants/constants';
import { buildUrl } from 'utils/utils';

export const useAnalyticsVisitPublicSnapshot = () => {
  return useMutation(async (uuid) => {
    const url = buildUrl(API_ANALYTICS_VISIT_PUBLIC_SNAPSHOT, [{ name: 'uuid', value: uuid }]);
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!res.ok) {
      const errorMessage = await res.json();
      throw new Error(`Creation failed: ${errorMessage}`);
    }

    return res.json();
  });
};

export const useAnalyticsSnapshotContinued = () => {
  return useMutation(async (uuid) => {
    const url = buildUrl(API_ANALYTICS_SNAPSHOT_CONTINUED, [{ name: 'uuid', value: uuid }]);
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!res.ok) {
      const errorMessage = await res.json();
      throw new Error(`Analytics tracking failed: ${errorMessage}`);
    }

    return res.json();
  });
};
