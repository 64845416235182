import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { API_GS_VERSION } from 'constants/constants';
import { ApiClientContext } from 'context/api-client';
import { buildUrl } from 'utils/utils';

export const useVersionQuery = () => {
  const apiClient = useContext(ApiClientContext);

  return useQuery({
    queryKey: ['version'],
    queryFn: async () => {
      const res = await apiClient(buildUrl(API_GS_VERSION));
      if (!res.ok) throw Error('Load failed');
      return res.json();
    },
  });
};
