export const USER_MESSAGE = 'user';
export const ASSISTANT_MESSAGE = 'assistant';
export const SIDEBAR_NEW_CHAT = 0;
export const SIDEBAR_NEW_CHAT_V2 = 'new';
export const SIDEBAR_NONE_TOOL = 0;
export const WS_MESSAGE_TYPES = {
  JOINED_CONVERSATION: 'user_joined_conversation',
  LEAVED_CONVERSATION: 'user_leaved_conversation',
  OPENED_CONVERSATION: 'user_opened_conversation',
  READ_CONVERSATION: 'user_read_conversation',
  NEW_MESSAGES: 'new_messages',
  MESSAGE_REGENERATED: 'message_regenerated',
  MESSAGE_DELETED: 'messages_deleted',
  STREAMING_FAILED: 'streaming_failed',
  UNREAD_COUNT_UPDATE: 'unread_count_update',
};

export const API_GET_FEATURES = '/api/v1/payments/features/';
export const FEATURE_COLLABORATION = 'collaboration';
export const FEATURE_DATA_LOSS_PREVENTION = 'data_loss_prevention';
export const FEATURE_SLACK_INTEGRATION = 'collaboration';

export const API_SLACK_ADD = '/api/v1/integrations/apps/slack/add/';
export const API_SLACK_GET_CHANNELS = '/api/v1/integrations/apps/slack/channels/';
export const API_SLACK_SEND_MESSAGE = '/api/v1/integrations/apps/slack/send-message/';
export const API_SLACK_LINK = '/api/v1/integrations/apps/slack/link/:conversationId/';

export const API_REMEMBERIZER_OAUTH2_INITIATE =
  '/api/v1/integrations/apps/rememberizer/oauth-initiate/';
export const API_USER_REMEMBERIZER_INTEGRATIONS_LIST =
  '/api/v1/integrations/apps/user-rememberizer-integrations/';
export const API_USER_REMEMBERIZER_INTEGRATIONS_ACTIVATE =
  '/api/v1/integrations/apps/user-rememberizer-integrations/:id/activate/';
export const API_USER_REMEMBERIZER_INTEGRATIONS_DEACTIVATE =
  '/api/v1/integrations/apps/user-rememberizer-integrations/:id/deactivate/';
export const API_USER_REMEMBERIZER_INTEGRATIONS_REMOVE =
  '/api/v1/integrations/apps/user-rememberizer-integrations/:id/';

export const API_FETCH_CONVERSATIONS = '/api/v1/conversations/';
export const API_DETAIL_CONVERSATION = '/api/v1/conversations/:id';
export const API_DELETE_CONVERSATION = '/api/v1/conversations/:id';
export const API_SEND_MESSAGE = '/api/v1/conversations/chat_v2/';
export const API_DELETE_MESSAGE = '/api/v1/conversations/messages/:messageId';
export const API_STOP_MESSAGE = '/api/v1/conversations/messages/:messageId/stop-streaming/';
export const API_STREAM_MESSAGE = '/api/v1/conversations/streaming/';
export const API_FETCH_CONVERSATION_MESSAGES =
  '/api/v1/conversations/:id/messages?from_id=:fromMessageId&limit=:limit';
export const API_UPDATE_CONVERSATION_VIEW_SETTINGS = '/api/v1/conversations/:id/view-settings/';

export const API_FETCH_TOOLS = '/api/v1/tools/';
export const API_FETCH_TOOL_DETAIL = '/api/v1/tools/:id';
export const API_UPDATE_TOOL = '/api/v1/tools/:id';
export const API_FETCH_MODELS = '/api/v1/models/';
export const API_UPLOAD_PROMPTS = '/api/v1/tools/upload/';
export const API_DOWNLOAD_PROMPTS = '/api/v1/tools/:id/export/';
export const API_DELETE_TOOL = '/api/v1/tools/:id';
export const API_PROFILE = '/api/v1/members/detail';
export const API_SUBDOMAIN_INFOMATION = '/api/v1/accounts/:subdomain';
export const API_LOGIN_CALLBACK = '/oidc/callback/';
export const API_CHANGE_PASSWORD = '/api/v1/members/change_password/';
export const API_REQUEST_RESET_PASSWORD = '/api/v1/members/request_reset_password/';
export const API_LOGIN_PASSWORD = '/api/v1/authentication/password/';
export const API_TOKEN_VERIFICATION = '/api/v1/authentication/token-verification/:token/';
export const API_GS_VERSION = '/api/v1/core/gs-version';

export const API_ADD_ROUTINE = '/api/v1/conversations/routines';
export const API_FETCH_ROUTINES = '/api/v1/conversations/routines';
export const API_FETCH_ROUTINE_DETAIL = '/api/v1/conversations/routine/:id'; // UPDATE, DELETE
export const API_DELTE_ROUTINE = '/api/v1/conversations/routine/:id'; // UPDATE, DELETE

export const WS_CHAT = '/ws/connect/';

export const API_START_COLLABORATION = '/api/v1/conversations/collaboration/start';
export const API_STOP_COLLABORATION = '/api/v1/conversations/collaboration/stop';
export const API_VIEW_COLLABORATED_CONVERSATION = '/api/v1/conversations/collaboration/join';
export const API_LEAVE_CONVERSATION = '/api/v1/conversations/collaboration/leave';
export const API_EDIT_MESSAGE = '/api/v1/conversations/:conversationId/messages/:messageId/update';

export const SAMPLE_TOOL = 'https://skydeck-public-assets.s3.amazonaws.com/sample_tool.json';
export const SAMPLE_TOOL_CSV =
  'https://skydeck-public-assets.s3.amazonaws.com/sample_tool_user.csv';
export const SKYDECK_LANDING_PAGE = 'https://skydeck.ai';

export const PATH_SIGN_IN = '/sign-in';

export const MAX_OUTPUT_TOKENS = 2048;
export const TOKENS_TO_WORDS_RATIO = 0.75;
export const MAX_TOTAL_UPLOAD_SIZE = 20 * 1024 * 1024; // 20 MB in bytes
export const MAX_COPY_SIZE = 20 * 1024 * 1024; // 20 MB in bytes

export const ACCOUNT_REMEMBERIZER_INTEGRATION_ID = -1;

export const SUPPORTED_FILE_TYPES = {
  IMAGE: ['.jpeg', '.png', '.webp', '.jpg'],
  DOCUMENT: ['.doc', '.docx', '.csv', '.txt', '.pptx', '.pdf'],
};

// Ensure conversation sort options text values get detected and translated by i18n script
export const CONVERSATION_SORT_OPTIONS = [
  { value: 'sort_by=modified&sort_order=desc', text: 'Most recently updated' },
  { value: 'sort_by=created&sort_order=desc', text: 'Most recently created' },
  { value: 'sort_by=name&sort_order=asc', text: 'Name' },
  { value: 'sort_by=duration&sort_order=desc', text: 'Duration' },
  { value: 'sort_by=size&sort_order=desc', text: 'Size' },
];
export const API_DOWNLOAD_CONVERSATION = '/api/v1/conversations/:conversationId/markdown/';

export const API_SIGN_OUT = '/api/v1/authentication/logout/';
export const API_REFRESH_TOKEN = '/api/v1/authentication/token/refresh/';
export const API_PUBLIC_SNAPSHOT = '/api/v1/conversations/snapshot/:uuid';
export const API_CREATE_SNAPSHOT = '/api/v1/conversations/:conversation_id/create_snapshot';
export const API_FETCH_CONVERSATION_SNAPSHOTS = '/api/v1/conversations/:conversation_id/snapshots';
export const API_MANAGE_SNAPSHOT = '/api/v1/conversations/snapshot/:snapshot_id';
export const API_CONTINUE_SNAPSHOT = '/api/v1/conversations/continue_snapshot/:uuid';
export const API_FETCH_COLLABORATION_CONVERSATIONS_STARTED_BY_ME =
  '/api/v1/conversations/collaborations/me/';
export const API_FETCH_COLLABORATION_CONVERSATIONS_SHARED_WITH_ME =
  '/api/v1/conversations/collaborations/shared/';
export const API_FETCH_COLLABORATION_CONVERSATIONS_PUBLIC_SNAPSHOTS =
  '/api/v1/conversations/snapshots/public/';
export const API_SNAPSHOT_SIGNUP = '/api/v1/authentication/snapshot-signup/';
export const SERVER_ERROR = 'A server error occurred. Please try again later.';
export const GENERIC_ERROR = 'An error occurred. Please try again later.';
export const INVALID_TOKEN_ERROR = 'invalid_token';
export const PASSWORD_LOGIN_DISABLED = 'password_disabled';
export const GOOGLE_LOGIN_DISABLED = 'google_disabled';
export const MICROSOFT_LOGIN_DISABLED = 'microsoft_disabled';
export const PASSWORD_LOGIN = 'Email';
export const GOOGLE_LOGIN = 'Google';
export const MICROSOFT_LOGIN = 'Microsoft';

export const CONVERSATION_NAME_MAX_LENGTH = 60;
export const API_GET_CC_SESSION = '/api/v1/authentication/get-cc-session/';
export const API_ANALYTICS_VISIT_PUBLIC_SNAPSHOT = '/api/v1/analytics/visit_snapshot/:uuid';
export const API_ANALYTICS_SNAPSHOT_CONTINUED = '/api/v1/analytics/convo_continued/:uuid';

// carbon design breakpoints
export const BREAKPOINTS = {
  sm: 320,
  md: 672,
  lg: 1056,
  xlg: 1312,
  max: 1584,
};
