import { Button, Content, Theme } from '@carbon/react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ChangeViewModeModal from 'components_v3/ChangeViewModeModal';
import ContinueSnapshotModal from 'components_v3/ContinueSnapshotModal';
import { ConversationMessage } from 'components_v3/ConversationMessage';
import Loader from 'components_v3/Loader';
import Navbar from 'components_v3/Navbar';
import { NotFound } from 'components_v3/NotFound';
import { CONVERSATION_NAME_MAX_LENGTH, USER_MESSAGE } from 'constants/constants';
import { CONTINUE_SNAPSHOT_PATH } from 'constants/routes';
import { useSnapshotViewSettingsMutation, useSnapshotViewSettingsQuery } from 'hooks/view_settings';
import { useAnalyticsVisitPublicSnapshot } from 'hooks_v3/analytics';
import { usePublicSnapshot, useSnapshotSignupMutation } from 'hooks_v3/snapshots';
import { displaySnapshotTime } from 'utils/snapshot';

import 'components_v3/Conversation.scss';
import 'pages_v3/PublicSnapshot.scss';

const PublicSnapshot = () => {
  const { snapshot_uuid } = useParams();
  const { data, error, isLoading } = usePublicSnapshot(snapshot_uuid);
  const { t, i18n } = useTranslation();
  const snapshotSignupMutation = useSnapshotSignupMutation();
  const [openContinueModal, setOpenContinueModal] = useState(false);

  const analyticsVisitMutation = useAnalyticsVisitPublicSnapshot();
  useEffect(() => {
    analyticsVisitMutation.mutate(snapshot_uuid);
  }, []);

  const setDocumentTitle = (name = '') => {
    const maxLength = CONVERSATION_NAME_MAX_LENGTH;
    const suffix = ' - SkyDeck.ai GenStudio';
    if (name.length > maxLength - 6 && name.length <= maxLength) {
      document.title = name;
      return;
    }

    const fullTitle = `${name}${suffix}`;
    const truncate = (str, len) => (str.length > len ? `${str.slice(0, len - 3)}...` : str);
    const truncatedTitle = truncate(fullTitle, maxLength);
    document.title = truncatedTitle;
  };

  useEffect(() => {
    const conversationName = data?.conversation_data?.name || '';
    setDocumentTitle(conversationName);
  }, [data]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <NotFound />;
  }

  const { name, created, messages, llms } = data.conversation_data;

  const checkWorkspaceURL = async (workspaceURL) => {
    const url = `${window.location.protocol}//${workspaceURL}`;
    try {
      await fetch(url, { mode: 'no-cors', signal: AbortSignal.timeout(10000) });
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleCopyConversation = async (workspaceUrl) => {
    const checked = await checkWorkspaceURL(workspaceUrl);
    if (checked) {
      const newUrl = `${window.location.protocol}//${workspaceUrl}${CONTINUE_SNAPSHOT_PATH.replace(
        ':snapshot_uuid',
        snapshot_uuid,
      )}`;

      window.location.href = newUrl;
    } else {
      toast.error(t('We cannot find your workspace. Please try again.'));
    }
  };

  const handleSnapshotSignup = async (data) => {
    const res = await snapshotSignupMutation.mutateAsync(data);
    if (res.error) {
      if (res.error?.user_email) {
        toast.error(t('A user with this email already exists.'));
      }

      if (res.error?.snapshot) {
        toast.error(t('This snapshot has been removed by its owner.'));
      }

      return;
    }

    window.location.href = `${import.meta.env.VITE_BASE_URL}/sign-up/?email=${encodeURIComponent(
      data.user_email,
    )}`;
  };

  const handleRequestSubmit = async (data) => {
    if (data.email !== '') {
      return handleSnapshotSignup({
        user_email: data.email,
        snapshot: snapshot_uuid,
      });
    }

    if (data.workspaceURL) {
      return handleCopyConversation(data.workspaceURL);
    }
  };

  const [isBetterFormatOpen, setIsBetterFormatOpen] = useState(false);
  const [selectedMessageId, setSelectedMessageId] = useState(null);

  const { data: viewSettings, refetch: refetchViewSettings } = useSnapshotViewSettingsQuery(
    snapshot_uuid,
    data?.conversation_data?.view_settings || {
      userMarkdownView: true,
      userLatexView: true,
      assistantMarkdownView: true,
      assistantLatexView: true,
    },
  );

  useEffect(() => {
    if (data?.conversation_data) {
      refetchViewSettings();
    }
  }, [data?.conversation_data, refetchViewSettings]);

  const snapshotViewSettingsMutation = useSnapshotViewSettingsMutation(
    snapshot_uuid,
    data?.conversation_data?.view_settings,
  );

  const toggleUserMarkdownView = () =>
    snapshotViewSettingsMutation.mutate({ userMarkdownView: !viewSettings.userMarkdownView });
  const toggleUserLatexView = () =>
    snapshotViewSettingsMutation.mutate({ userLatexView: !viewSettings.userLatexView });
  const toggleAssistantMarkdownView = () =>
    snapshotViewSettingsMutation.mutate({
      assistantMarkdownView: !viewSettings.assistantMarkdownView,
    });
  const toggleAssistantLatexView = () =>
    snapshotViewSettingsMutation.mutate({ assistantLatexView: !viewSettings.assistantLatexView });

  const handleBetterFormatClick = (messageId) => {
    setSelectedMessageId(messageId);
    setIsBetterFormatOpen(true);
  };

  const handleCloseBetterFormat = () => {
    setIsBetterFormatOpen(false);
    setSelectedMessageId(null);
  };

  const handleSaveBetterFormat = () => {
    snapshotViewSettingsMutation.mutate({
      userMarkdownView: viewSettings.userMarkdownView,
      userLatexView: viewSettings.userLatexView,
      assistantMarkdownView: viewSettings.assistantMarkdownView,
      assistantLatexView: viewSettings.assistantLatexView,
    });
    setIsBetterFormatOpen(false);
    setSelectedMessageId(null);
  };

  return (
    <div id='snapshot-main-layout'>
      <Navbar
        options={{ showCompanyLogo: false, showDocs: true, showAccount: false, showSideNav: false }}
      />
      <Content id='public-main-content' className='snapshot-page-main-content'>
        <div id='snapshot-home'>
          <div id='conversation'>
            <div id='conversation-chat' className='conversation'>
              <Theme theme='g90'>
                <ul id='messages-container' className='scrollbar-thin'>
                  <li
                    className='message assistant-message snapshot-title'
                    data-cy='conversation-message'>
                    <div className='message-wrapper'>
                      <div className='content'>
                        <p className='title-name'>{name}</p>
                        <p className='title-created'>
                          {created ? displaySnapshotTime(created, i18n.language) : ''}
                        </p>
                      </div>
                    </div>
                  </li>
                  {messages.map((message) => (
                    <ConversationMessage
                      key={message.id}
                      message={message}
                      streamingResponse=''
                      isError={false}
                      isConversationOwner={false}
                      isDeletionDisabled={true}
                      isShown={true}
                      snapshot_llms={llms}
                      inSnapshot={true}
                      userMarkdownView={viewSettings.userMarkdownView}
                      userLatexView={viewSettings.userLatexView}
                      assistantMarkdownView={viewSettings.assistantMarkdownView}
                      assistantLatexView={viewSettings.assistantLatexView}
                      onBetterFormatClick={() => handleBetterFormatClick(message.id)}
                    />
                  ))}
                </ul>
              </Theme>
            </div>
            <div className='message-wrapper button-wrapper'>
              <div className='content'>
                <div className='continue-button-wrapper'>
                  <Button kind='primary' onClick={() => setOpenContinueModal(true)}>
                    {t('Continue this conversation')}
                  </Button>
                </div>

                {typeof document !== 'undefined' &&
                  createPortal(
                    <ContinueSnapshotModal
                      open={openContinueModal}
                      setOpen={setOpenContinueModal}
                      onRequestSubmit={handleRequestSubmit}
                      snapshotUuid={snapshot_uuid}
                    />,
                    document.body,
                  )}
              </div>
            </div>
          </div>
        </div>
      </Content>
      <ChangeViewModeModal
        open={isBetterFormatOpen}
        markdownView={
          selectedMessageId
            ? messages.find((m) => m.id === selectedMessageId)?.type === USER_MESSAGE
              ? viewSettings.userMarkdownView
              : viewSettings.assistantMarkdownView
            : false
        }
        toggleMarkdownView={
          selectedMessageId
            ? messages.find((m) => m.id === selectedMessageId)?.type === USER_MESSAGE
              ? toggleUserMarkdownView
              : toggleAssistantMarkdownView
            : () => {}
        }
        latexView={
          selectedMessageId
            ? messages.find((m) => m.id === selectedMessageId)?.type === USER_MESSAGE
              ? viewSettings.userLatexView
              : viewSettings.assistantLatexView
            : false
        }
        toggleLatexView={
          selectedMessageId
            ? messages.find((m) => m.id === selectedMessageId)?.type === USER_MESSAGE
              ? toggleUserLatexView
              : toggleAssistantLatexView
            : () => {}
        }
        onSave={handleSaveBetterFormat}
        onClose={handleCloseBetterFormat}
      />
    </div>
  );
};

export default PublicSnapshot;
