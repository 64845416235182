import { UserAvatar } from '@carbon/icons-react';
import { OverflowMenu, OverflowMenuItem, Theme, ActionableNotification } from '@carbon/react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ReadyState } from 'react-use-websocket';

import PopoverWrapper from 'components_v3/PopoverWrapper';
import { ACCOUNT_SETTINGS_PATH } from 'constants/routes';
import { WebSocketContext } from 'context/websocket';
import { useProfileQuery } from 'hooks/account';
import { useCCSessionMutation, useLogout } from 'hooks/authentication';
import { useVersionQuery } from 'hooks/version';
import { getAbsoluteURL } from 'utils/utils';

import './MenuProfile.scss';

export const MenuProfile = () => {
  const profileQuery = useProfileQuery();
  const latestVersionQuery = useVersionQuery();
  const { t } = useTranslation();
  const logout = useLogout();
  const CCSessionMutation = useCCSessionMutation();
  const navigate = useNavigate();
  const [shouldAutoOpenMenuNewVersion, setShouldAutoOpenMenuNewVersion] = useState(true);
  const websocketReady = useContext(WebSocketContext)?.readyState === ReadyState.OPEN;

  const currentVersionTimestamp = import.meta.env.VITE_VERSION_TIMESTAMP;
  const currentVersion = import.meta.env.VITE_VERSION?.split('/').pop();

  const onSignOutClick = () => {
    logout();

    document.title = 'SkyDeck.ai GenStudio';
  };

  const onManageAccountClick = async () => {
    const res = await CCSessionMutation.mutateAsync();
    if (res.ok) {
      window.open(import.meta.env.VITE_CC_URL);
    } else {
      toast.error(t('You do not have managing permission!'));
    }
  };

  const onAccountSettingsClick = () => {
    navigate(ACCOUNT_SETTINGS_PATH);
  };

  const hasUpdate = Boolean(
    latestVersionQuery.data?.timestamp &&
      String(latestVersionQuery.data.timestamp) !== currentVersionTimestamp,
  );

  return (
    <div className='account'>
      <OverflowMenu
        open={hasUpdate && shouldAutoOpenMenuNewVersion}
        onClose={() => {
          if (hasUpdate && shouldAutoOpenMenuNewVersion) {
            setShouldAutoOpenMenuNewVersion(false);
          }
        }}
        flipped
        renderIcon={() => (
          <PopoverWrapper content={<div className='account__tooltip'>{t('Account')}</div>}>
            <div className='avatar-container'>
              {profileQuery.data && profileQuery.data.data.image_url ? (
                <img
                  src={getAbsoluteURL(profileQuery.data.data.image_url)}
                  alt='User Avatar'
                  className='user-avatar'
                  width={20}
                  height={20}
                />
              ) : (
                <UserAvatar size={20} />
              )}
              {hasUpdate ? (
                <div className='notification-dot' />
              ) : (
                !websocketReady && <div className='notification-dot yellow' />
              )}
            </div>
          </PopoverWrapper>
        )}
        size='lg'
        data-floating-menu-container
        menuOptionsClass='profile-overflow-menu'
        focusTrap={false}>
        {profileQuery.data && (
          <PopoverWrapper
            content={<div className='account__tooltip'>{profileQuery.data.data.email}</div>}>
            <div className='profile-item-wrapper cds--overflow-menu-options__btn'>
              <span className='cds--overflow-menu-options__option-content'>
                {profileQuery.data.data.name}
              </span>
              <span className='cds--overflow-menu-options__option-content'>
                {profileQuery.data.data.email}
              </span>
            </div>
          </PopoverWrapper>
        )}
        <div className='profile-item-wrapper cds--overflow-menu--divider'>
          <span className='cds--overflow-menu-options__option-content'>
            About GenStudio: {currentVersion ? `v${currentVersion}` : 'N/A'}
          </span>
          {hasUpdate && (
            <Theme theme='white' className='update-notification-wrapper'>
              <ActionableNotification
                className='update-notification'
                kind='info'
                title='Update available'
                subtitle={t(
                  'A new version of GenStudio is available. Please refresh the page to update.',
                )}
                actionButtonLabel='Refresh'
                hideCloseButton
                onActionButtonClick={() => window.location.reload()}
              />
            </Theme>
          )}
        </div>
        {profileQuery.data?.data?.is_owner_or_admin && (
          <OverflowMenuItem
            hasDivider
            wrapperClassName='manage-account-btn-wrapper'
            itemText={t('Manage Account')}
            onClick={onManageAccountClick}
            requireTitle
          />
        )}

        <OverflowMenuItem
          hasDivider
          itemText={t('Account Settings')}
          onClick={onAccountSettingsClick}
          requireTitle
        />
        <OverflowMenuItem
          hasDivider
          itemText={t('Sign out')}
          onClick={onSignOutClick}
          requireTitle
        />
      </OverflowMenu>
    </div>
  );
};

export default MenuProfile;
