import {
  ComposedModal,
  ModalHeader,
  TextInput,
  Button,
  ModalFooter,
  Theme,
  ModalBody,
} from '@carbon/react';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAnalyticsSnapshotContinued } from 'hooks_v3/analytics';

import './ContinueSnapshotModal.scss';

const ContinueSnapshotModal = ({ open, setOpen, onRequestSubmit, snapshotUuid }) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [subdomain, setSubdomain] = useState('');
  const [emailError, setEmailError] = useState('');

  const domainName = `.${window.location.hostname.split('.').slice(1).join('.')}`;

  const workspaceURL = subdomain != '' ? `${subdomain}${domainName}` : '';

  const analyticsContinuedMutation = useAnalyticsSnapshotContinued();

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (value != '' && !re.test(value.toLowerCase())) {
      setEmailError('Invalid email');
    } else {
      setEmailError('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && ((email !== '' && emailError === '') || workspaceURL !== '')) {
      handleSubmit({ workspaceURL, email });
    }
  };

  const handleSubmit = async (data) => {
    try {
      await analyticsContinuedMutation.mutateAsync(snapshotUuid);
      onRequestSubmit(data);
    } catch (error) {
      console.error('Failed to track continuation:', error);
      onRequestSubmit(data);
    }
  };

  return (
    <Theme theme='g100' className='continue-snapshot-modal'>
      <ComposedModal open={open} onClose={() => setOpen(false)}>
        <ModalHeader className='modal-header' title={t('Continue this conversation')} />
        <ModalBody className='modal-content'>
          <p className='section-header'>
            {t('New to SkyDeck.ai? Enter your work email to get started:')}
          </p>
          <TextInput
            id='email-input'
            className='input-field'
            labelText=''
            placeholder={t('you@company.com')}
            enableCounter={true}
            autoComplete='email'
            type='email'
            value={email}
            onChange={handleEmailChange}
            onKeyDown={handleKeyDown}
            disabled={workspaceURL != ''}
            invalid={emailError != ''}
            invalidText={emailError}
          />
          <p className='section-description'>
            {t(
              'We’ll guide you through creating a new workspace in the SkyDeck Control Center. Once set up, you’ll be able to continue this conversation and explore more with GenStudio.',
            )}
          </p>

          <div className='hr-with-text light'>
            <span>{t('Or')}</span>
          </div>

          <p className='section-header'>{t('Already have an account? Welcome back!')}</p>

          <div className='input-wrapper'>
            <TextInput
              id='subdomain'
              className='subdomain'
              type='text'
              name='subdomain'
              autoComplete='on'
              labelText='subdomain'
              hideLabel
              placeholder={t('your company subdomain name')}
              required
              value={subdomain}
              onChange={(e) => setSubdomain(e.target.value)}
              onKeyDown={handleKeyDown}
              disabled={email != ''}
            />
            <Theme theme='g10' className='domain'>
              <TextInput labelText='' readOnly id='domain-name' value={domainName}></TextInput>
            </Theme>
          </div>

          <p className='section-description'>
            {t(
              "You'll be able to pick up where this conversation left off and continue it in your own workspace.",
            )}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button kind='ghost' onClick={() => setOpen(false)}>
            {t('Cancel')}
          </Button>
          <Button
            disabled={email === '' && workspaceURL === ''}
            kind='primary'
            onClick={() => handleSubmit({ workspaceURL: workspaceURL, email })}>
            {t('Continue')}
          </Button>
        </ModalFooter>
      </ComposedModal>
    </Theme>
  );
};

ContinueSnapshotModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onRequestSubmit: PropTypes.func.isRequired,
  checkingWorkspaceURL: PropTypes.bool.isRequired,
  snapshotUuid: PropTypes.string.isRequired,
};

export default ContinueSnapshotModal;
